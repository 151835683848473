import React, { useState } from 'react';
import ExcelJS from 'exceljs';
import { useHeaderHeight } from '@react-navigation/elements';
import { StyleSheet, Text, View, FlatList, TextInput, Pressable, Modal, ScrollView, Alert, TouchableOpacity, ActivityIndicator } from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { SearchBar } from 'react-native-elements';
import { db } from '../../db/firestore';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import constants from "../../utils/constants";

const ClientList = ({ navigation, userInfo }) => {
    const [clientList, setClientList] = useState(userInfo.clientList || []);
    const [displayedList, setDisplayedList] = useState(clientList);
    const [newClient, setNewClient] = useState({ name: '', email: '' });
    const [search, setSearch] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [validationError, setValidationError] = useState('');
    const [loading, setLoading] = useState(false)
    const headerHeight = useHeaderHeight();

    const chamberType = {
        1: "H",
        2: "S",
    };

    const handleSearch = (text) => {
        setSearch(text);
        const filteredList = clientList.filter(client =>
            client.name.toLowerCase().includes(text.toLowerCase()) ||
            client.email.toLowerCase().includes(text.toLowerCase())
        );
        setDisplayedList(filteredList);
    };

    const exportToExcel = async (clientArr) => {
        setLoading(true)
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Clients');
            worksheet.columns = [
                { width: 16 },  // Priority
                { width: 20 },  // Bill Name (Hyperlinked)
                { width: 30 },  // Bill Status
                { width: 30 },  // Bill Title
                { width: 70 },  // Bill Summary
                { width: 30 },  // Bill Sponsors
                { width: 45 },  // Notes
            ];

            for (const client of clientArr) {
                // Add client's name and email in bold
                const row = worksheet.addRow(['', client.name.trim(), client.email.trim()]);
                row.font = { bold: true };

                // Sort bills by priority
                const priorityOrder = { High: 1, Moderate: 2, Low: 3 };
                client.bills.sort((a, b) => {
                    const priorityA = priorityOrder[a.billPriority] || 4;
                    const priorityB = priorityOrder[b.billPriority] || 4;
                    return priorityA - priorityB;
                });

                // Fetch all bill data from Firestore
                const billsWithData = await Promise.all(client.bills.map(async (bill) => {
                    try {
                        const docRef = doc(db, "legislation", `${bill.billId}`);
                        const docSnap = await getDoc(docRef);

                        if (docSnap.exists()) {
                            const data = docSnap.data();
                            const clientSponsorList = [];
                            if (data.sponsors?.length > 0) {
                                for (let i = 0; i < data.sponsors.length; i += 1) {
                                    const sponsor = data.sponsors[i];
                                    clientSponsorList.push(`${sponsor.name} (${chamberType[sponsor.chamber]}) ${sponsor.district}`);
                                }
                            }
                            return {
                                ...bill,
                                billSubject: data.title.trim(),
                                billStatus: data.status.trim(),
                                billPdfLink: `https://www.legis.ga.gov/api/legislation/document/${data.session.library}/${data.versions[0].id}`,
                                billSponsors: clientSponsorList,
                                billSummary: (data.firstReader || "No Summary Available").trim(),
                            };
                        }
                    } catch (error) {
                        console.error(`Error fetching bill ${bill.billId}:`, error);
                    }
                    return bill; // Return original bill if fetch fails
                }));

                // Add each bill to the Excel sheet
                for (const bill of billsWithData) {
                    const sponsorString = bill.billSponsors.length > 0
                        ? `Sponsors:\n${bill.billSponsors.join("\n")}`.trim()
                        : "No Sponsors";

                    const billRow = worksheet.addRow([
                        `Priority: ${bill.billPriority.toUpperCase().trim()}\n${bill.billIssue ? `Issue:\n${bill.billIssue.toUpperCase().trim()}` : ''}`,
                        { text: bill.billName.trim(), hyperlink: bill.billPdfLink }, // Hyperlinked Bill Name
                        `Status:\n${bill.billStatus.trim()}`,
                        `Title:\n${bill.billSubject.trim()}`,
                        `Summary:\n${bill.billSummary.trim()}`,
                        sponsorString,
                        `Client Notes:\n${bill.billNotes ? bill.billNotes.trim() : 'No Notes to show'}`,
                    ]);

                    // Apply hyperlink style to Bill Name
                    billRow.getCell(2).font = { color: { argb: 'FF0000FF' }, underline: true, size: 14, bold: true };
                    billRow.getCell(2).alignment = { horizontal: 'center' };

                    // Set text alignment to top
                    billRow.eachCell((cell) => {
                        cell.alignment = { vertical: 'top', wrapText: true };
                    });

                    // Apply priority fill color
                    const priorityColors = {
                        High: 'FFFFCCCC',      // Light red
                        Moderate: 'FFFFFF99',  // Light yellow
                        Low: '99CCFF',         // Light blue
                        Default: 'FFFFFFFF'    // No fill
                    };
                    const fillColor = priorityColors[bill.billPriority] || priorityColors.Default;
                    billRow.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } };

                    // Apply borders to the row
                    billRow.eachCell((cell) => {
                        cell.border = {
                            top: { style: 'thin', color: { argb: 'FF000000' } },
                            left: { style: 'thin', color: { argb: 'FF000000' } },
                            bottom: { style: 'thin', color: { argb: 'FF000000' } },
                            right: { style: 'thin', color: { argb: 'FF000000' } },
                        };
                    });
                }

                // Add an empty row between clients
                worksheet.addRow([]);
            }

            // Enable text wrapping for all columns
            worksheet.columns.forEach((column) => {
                column.alignment = { wrapText: true, vertical: 'top' };
            });

            // Generate and download the Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'clients.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            setLoading(false)
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            setLoading(false)
        }
    };

    const openModal = (client) => {
        setSelectedClient(client);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setSelectedClient(null);
    };

    const addClient = async () => {
        if (!newClient.name || !newClient.email) {
            setValidationError('Both name and email are required.');
            return;
        }

        setValidationError(''); // Clear the error if validation passes

        const updatedClientList = [...clientList, { ...newClient, bills: [] }];
        setClientList(updatedClientList); // Optimistically update
        setDisplayedList(updatedClientList);
        setNewClient({ name: '', email: '' });

        try {
            const docRef = doc(db, "users", userInfo.email); // Using email as documentId
            await updateDoc(docRef, { clientList: updatedClientList });
        } catch (error) {
            console.error("Error adding client: ", error);
            const revertedList = clientList.slice(0, -1); // Revert state
            setClientList(revertedList);
            setDisplayedList(revertedList);
        }
    };

    const deleteClient = async (index) => {
        const removedClient = displayedList[index];
        const updatedClientList = clientList.filter(client => client !== removedClient);
        setClientList(updatedClientList); // Optimistically update
        setDisplayedList(updatedClientList);

        try {
            const docRef = doc(db, "users", userInfo.email);
            await updateDoc(docRef, { clientList: updatedClientList });
        } catch (error) {
            console.error("Error deleting client: ", error);
            const revertedList = [...clientList]; // Revert state
            setClientList(revertedList);
            setDisplayedList(revertedList);
        }
    };

    return (
        <View style={{ height: '100%', width: '100%', backgroundColor: constants.PRIMARY_DARK, alignItems: 'center' }}>
            <View style={[styles.container, { marginTop: headerHeight, alignItems: 'center' }]}>
                <View style={{ width: '96%', backgroundColor: constants.PRIMARY_DARK }}>
                    <View style={[styles.inputContainer, { alignItems: 'center' }]}>
                        <Text style={{ marginRight: 10, color: constants.SUBTEXT_COLOR, fontSize: 18, fontWeight: 'bold' }}>
                            Add Client:
                        </Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Client Name"
                            placeholderTextColor={constants.PLACEHOLDER_COLOR}
                            value={newClient.name}
                            onChangeText={(text) => setNewClient((prev) => ({ ...prev, name: text }))}
                        />
                        <TextInput
                            style={[styles.input, { marginRight: 20 }]}
                            placeholder="Client Email"
                            placeholderTextColor={constants.PLACEHOLDER_COLOR}
                            value={newClient.email}
                            onChangeText={(text) => setNewClient((prev) => ({ ...prev, email: text }))}
                            keyboardType="email-address"
                        />
                        <Pressable style={styles.addButton} onPress={addClient}>
                            <AntDesign name="pluscircle" size={26} color={constants.ICON_COLOR} />
                        </Pressable>
                    </View>
                    {validationError ? (
                        <Text style={{ color: 'red', marginTop: 5 }}>{validationError}</Text>
                    ) : null}
                    <Pressable style={[styles.exportButton, { marginBottom: 15, padding: 10 }]} onPress={() => exportToExcel(clientList)}>
                        <AntDesign name="export" size={20} color={constants.ICON_COLOR} />
                        <Text style={styles.exportText}>Export All Clients to Excel  </Text>
                    </Pressable>
                    <SearchBar
                        placeholder="Search Clients"
                        onChangeText={handleSearch}
                        value={search}
                        clearIcon
                        darkTheme
                        containerStyle={styles.searchContainer}
                        inputContainerStyle={styles.searchInputContainer}
                    />

                    <FlatList
                        data={displayedList}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item, index }) => (
                            <View style={styles.clientItem}>
                                <View style={styles.rowContainer}>
                                    <View style={styles.textContainer}>
                                        <Text style={styles.textStyle}>{item.name}</Text>
                                        <Text style={styles.textStyle}>({item.email})</Text>
                                    </View>
                                    <View style={styles.buttonContainer}>
                                        {item.bills && item.bills.length > 0 && (
                                            <>
                                                <Pressable style={styles.modalButton} onPress={() => openModal(item)}>
                                                    <Text style={styles.modalButtonText}>View Bills</Text>
                                                </Pressable>
                                                <Pressable style={styles.exportButton} onPress={() => exportToExcel([item])}>
                                                    <AntDesign name="export" size={20} color={constants.ICON_COLOR} />
                                                    <Text style={styles.exportText}>Export Client Bills</Text>
                                                </Pressable>
                                            </>
                                        )}
                                        <Pressable onPress={() => deleteClient(index)}>
                                            <MaterialIcons name="cancel" size={24} color={constants.ICON_COLOR} />
                                        </Pressable>
                                    </View>
                                </View>
                            </View>
                        )}
                    />
                </View>


            </View>

            {/* Modal for Viewing Bills */}
            {selectedClient && (
                <Modal
                    visible={modalVisible}
                    animationType="fade"
                    transparent
                    onRequestClose={closeModal}
                >
                    <View style={styles.modalContainer}>
                        <View style={styles.modalContent}>
                            <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
                                <AntDesign name="close" size={22} color={constants.ICON_COLOR} />
                            </TouchableOpacity>
                            <Text style={styles.modalHeader}>{selectedClient.name}'s Bills</Text>
                            <ScrollView style={styles.modalScrollView}>
                                {selectedClient.bills.map((bill, idx) => (
                                    <Pressable
                                        key={bill.billId}
                                        style={styles.billButton}
                                        onPress={() => {
                                            navigation.navigate('Bill', {
                                                title: bill.billName,
                                                billId: bill.billId,
                                                options: { headerBackTitleVisible: false }
                                            })
                                            closeModal()
                                        }}
                                    >
                                        <View key={idx} style={styles.billItem}>
                                            <Text style={[styles.modalButtonText, { fontWeight: 'bold' }]}>{bill.billName}</Text>
                                            <Text style={styles.textStyle}>Subject: {bill.billSubject}</Text>
                                            <Text style={styles.textStyle}>Issue: {bill.billIssue}</Text>
                                            <Text style={styles.textStyle}>Priority: {bill.billPriority}</Text>
                                        </View>
                                    </Pressable>
                                ))}
                            </ScrollView>
                        </View>
                    </View>
                </Modal>
            )}
            {loading && (
                <View style={styles.loadingOverlay}>
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                </View>
            )}
        </View>
    );
};

export default ClientList;

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        height: '100%',
        width: '100%',
        padding: 10,
    },
    searchContainer: {
        backgroundColor: constants.PRIMARY_DARK,
        width: '100%',
        alignSelf: 'center',
        padding: 0,
        marginBottom: 15,
    },
    searchInputContainer: {
        borderRadius: 9,
    },
    inputContainer: {
        backgroundColor: constants.PRIMARY_DARK,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 25,
        marginTop: 10,
        width: '100%',
    },
    input: {
        flex: 1,
        backgroundColor: constants.SECONDARY_DARK,
        borderRadius: 5,
        color: constants.SUBTEXT_COLOR,
        padding: 15,
        marginHorizontal: 5,
        fontSize: 16,
    },
    addButton: {
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
    },
    exportButton: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: constants.SECONDARY_DARK,
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 5,
        justifyContent: 'center',
    },
    exportText: {
        marginLeft: 5,
        color: constants.ICON_COLOR,
        fontSize: 16,
    },
    clientItem: {
        backgroundColor: constants.SECONDARY_DARK,
        borderRadius: 5,
        padding: 10,
        marginBottom: 10,
        width: '100%'
    },
    textContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end', // Aligns buttons to the right
        alignItems: 'center',
        gap: 10, // Adds spacing between buttons (or use `marginLeft` per button)
        minWidth: 200, // Ensures buttons stay in a fixed-width container for alignment
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },
    textStyle: {
        color: constants.SUBTEXT_COLOR,
        fontSize: 16,
    },
    modalButton: {
        backgroundColor: constants.PRIMARY_DARK,
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalButtonText: {
        color: constants.SUBTEXT_COLOR,
        fontSize: 14,
    },
    modalContainer: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        maxHeight: '80%',
        minHeight: '40%',
        backgroundColor: constants.SECONDARY_DARK,
        borderRadius: 10,
        padding: 20,
    },
    modalHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#e5e5e5',
        textAlign: 'center',
        marginVertical: 10,
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 15,
        zIndex: 1,
    },
    modalScrollView: {
        width: '98%',
        backgroundColor: constants.PRIMARY_DARK,
        borderRadius: 10,
        padding: 10,
    },
    billItem: {
        backgroundColor: constants.SECONDARY_DARK,
        padding: 10,
        borderRadius: 5,
        marginBottom: 10,
    },
    billButton: {
        backgroundColor: constants.SECONDARY_DARK,
        width: '95%',
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 5,
        alignSelf: 'center',
        justifyContent: 'center',
        marginTop: 3,
        marginBottom: 3,
    },
    closeButtonText: {
        color: constants.SUBTEXT_COLOR,
        fontSize: 16,
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10, // Ensure it stays on top
    }
});