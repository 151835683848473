import React, { useState, useEffect } from 'react'
import { useHeaderHeight } from '@react-navigation/elements';
import constants from '../../utils/constants';
import { StyleSheet, ActivityIndicator, Text, View, ScrollView, Pressable, Button, Platform } from 'react-native'
import { db } from '../../db/firestore';
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { Dropdown } from 'react-native-element-dropdown';
import { AntDesign, FontAwesome, Ionicons } from '@expo/vector-icons';

const chamberData = [
    { label: 'House', value: '1' },
    { label: 'Senate', value: '2' },
    { label: 'House/Senate', value: '3' },
];

const CalendarsTable = ({ route, navigation }) => {
    const { title, email } = route.params;
    const [tableData, setTableData] = useState(<Text> </Text>);
    const [allData, setAllData] = useState(<Text> </Text>);
    const [houseData, setHouseData] = useState(<Text> </Text>);
    const [senateData, setSenateData] = useState(<Text> </Text>);
    const [chamberValue, setChamberValue] = useState('3');
    const [datesLoaded, setDatesLoaded] = useState(false);
    const [datesJson, setDatesJson] = useState();
    const [dateData, setDateData] = useState([{ label: 'Day 40', value: '1' }])
    const [dateValue, setDateValue] = useState('0');
    const [pageDate, setPageDate] = useState('')
    const [isChamberFocus, setIsChamberFocus] = useState(false);
    const [isDateFocus, setIsDateFocus] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const headerHeight = useHeaderHeight();

    const getData = async () => {
        setPageLoading(true);

        if (!datesLoaded) {
            const docRef = doc(db, "calendarDays", '1031');
            await getDoc(docRef)
                .then(response => response.data().days)
                .then(days => {
                    setDatesJson(days);
                    const datesArr = days.map((day, i) => ({
                        label: `Day ${day.day} ${day.date.substring(0, 4)}`,
                        date: day.date.split("T")[0], // Extract YYYY-MM-DD
                        value: i,
                    }));
                    setDateData(datesArr);
                    setDatesLoaded(true);
                });
        } else {
            const allDataArr = [];
            const houseDataArr = [];
            const senateDataArr = [];

            const selectedDate = datesJson[dateValue]?.date.split("T")[0]; // Extract YYYY-MM-DD
            if (!selectedDate) {
                setAllData([]);
                setHouseData([]);
                setSenateData([]);
                setTableData([]);
                setPageLoading(false);
                return;
            }
            setPageDate(selectedDate);
            // Query allCalendars collection for documents matching dayDate
            const querySnapshot = await getDocs(query(collection(db, "allCalendars"), where("dayDate", "==", selectedDate)));

            if (querySnapshot.empty) {
                setAllData([]);
                setHouseData([]);
                setSenateData([]);
                setTableData([]);
            } else {
                const calendars = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        chamber: data.chamber,
                        title: data.title,
                        date: data.dayDate,
                        billsResolutions: data.billsResolutions,
                        url: data.url
                    };
                });

                for (let i = 0; i < calendars.length; i += 1) {
                    const calendarObj = (
                        <Pressable
                            key={i}
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => navigation.navigate('ExternalView', {
                                title: "Calendar Info",
                                link: calendars[i].url,
                                calendar: calendars[i],
                                options: { headerBackTitleVisible: false }
                            })}
                        >
                            <View style={styles.rowContainer}>
                                <FontAwesome
                                    style={{ alignSelf: 'center', marginRight: 8 }}
                                    name="list-alt"
                                    size={34}
                                    color={constants.ICON_COLOR}
                                />
                                <View style={{ width: '98%' }}>
                                    <View style={styles.rowContainer}>
                                        <Text style={styles.textStyle}>{calendars[i].title}</Text>
                                    </View>
                                </View>
                            </View>
                        </Pressable>
                    );

                    allDataArr.push(calendarObj);
                    if (calendars[i].chamber === 1) {
                        houseDataArr.push(calendarObj);
                    } else if (calendars[i].chamber === 2) {
                        senateDataArr.push(calendarObj);
                    }
                }

                setAllData(allDataArr);
                setHouseData(houseDataArr);
                setSenateData(senateDataArr);
                setTableData(allDataArr);
            }
        }

        setPageLoading(false);
    };

    useEffect(() => {
        getData();
    }, [datesLoaded, dateValue]);

    // useEffect(() => {
    //     if (datesLoaded && dateData.length > 0) {
    //         const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD
    //         const todayIndex = dateData.findIndex(item => item.date === today);
    //         if (todayIndex !== -1) {
    //             setDateValue(todayIndex.toString()); // Set dropdown to today's index
    //         }
    //     }
    // }, [datesLoaded]);

    useEffect(() => {
        if (chamberValue === '1') {
            setTableData(houseData)
        } else if (chamberValue === '2') {
            setTableData(senateData)
        } else {
            setTableData(allData)
        }
    }, [chamberValue])

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <View style={styles.trackedButtons}>
                    <Dropdown
                        style={[styles.dropdown, isDateFocus && { borderColor: 'blue' }, { marginRight: 8, width: 170 }]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        fontFamily='Verdana'
                        containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                        activeColor='#3f3f3f'
                        inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                        iconStyle={styles.iconStyle}
                        data={dateData}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isDateFocus ? dateData[0].label : '...'}
                        value={dateValue}
                        onFocus={() => setIsDateFocus(true)}
                        onBlur={() => setIsDateFocus(false)}
                        onChange={item => {
                            setDateValue(item.value);
                            setPageDate(item.date);
                            setIsDateFocus(false);
                            setChamberValue('3')
                        }}
                        renderLeftIcon={() => (
                            <AntDesign style={styles.icon} name="calendar" size={20} color={constants.ICON_COLOR} />
                        )}
                    />
                    <Dropdown
                        style={[styles.dropdown, isChamberFocus && { borderColor: 'blue' }, { width: 180 }]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        fontFamily='Verdana'
                        containerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemContainerStyle={{ backgroundColor: '#242424', borderRadius: 9 }}
                        itemTextStyle={{ color: 'white', fontFamily: 'Verdana' }}
                        activeColor='#3f3f3f'
                        inputSearchStyle={{ color: 'white', borderRadius: 9, fontFamily: 'Verdana' }}
                        iconStyle={styles.iconStyle}
                        data={chamberData}
                        maxHeight={300}
                        labelField="label"
                        valueField="value"
                        placeholder={!isChamberFocus ? 'All' : '...'}
                        searchPlaceholder="Search..."
                        value={chamberValue}
                        onFocus={() => setIsChamberFocus(true)}
                        onBlur={() => setIsChamberFocus(false)}
                        onChange={item => {
                            setChamberValue(item.value);
                            setIsChamberFocus(false);
                        }}
                        renderLeftIcon={() => (
                            <FontAwesome style={styles.icon} name="building-o" size={18} color={constants.ICON_COLOR} />
                        )}
                    />
                </View>
                <View style={styles.dateView}>
                    <Text style={styles.dateText}>{pageDate}</Text>
                </View>
                <ScrollView style={styles.scrollView}>
                    {pageLoading ?
                        <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                        :
                        tableData
                    }
                </ScrollView>
            </View>
        </View>
    )
}

export default CalendarsTable

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        flex: 1
    },
    dropdown: {
        backgroundColor: '#242424',
        height: 40,
        borderColor: 'gray',
        borderWidth: 1.5,
        borderRadius: 9,
        paddingHorizontal: 8,
        marginBottom: 10,
    },
    scrollView: {
        // marginBottom: 55
    },
    dateView: {
        alignSelf: 'center'
    },
    dateText: {
        color: constants.TITLE_COLOR,
        marginBottom: 6,
        marginLeft: 7,
        fontSize: 16,
        fontWeight: "bold",
        fontFamily: 'Verdana'
    },
    button: {
        borderRadius: 9,
        borderWidth: 2,
        borderColor: 'black',
        paddingTop: 12,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        flexGrow: 1,
        marginBottom: 1,
    },
    trackedButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    rowContainer: {
        flexDirection: 'row',
        flexGrow: 1,
        width: "90%",
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        width: "95%",
        marginLeft: 7,
        fontSize: 13.2,
        fontWeight: "bold",
        fontFamily: 'Verdana',
        alignSelf: 'center',
        ...Platform.select({
            ios: {
                lineHeight: 20
            },
            android: {}
        })
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        width: "95%",
        height: 20,
        marginTop: 2,
        marginLeft: 7,
        fontSize: 12,
        fontWeight: "bold",
        textAlignVertical: "center",
    },
    icon: {
        marginRight: 5,
    },
    label: {
        position: 'absolute',
        backgroundColor: 'white',
        left: 22,
        top: 8,
        zIndex: 999,
        paddingHorizontal: 8,
        fontSize: 14,
    },
    placeholderStyle: {
        fontSize: 16,
        color: '#e5e5e5'
    },
    selectedTextStyle: {
        fontSize: 16,
        color: '#e5e5e5'
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
})
