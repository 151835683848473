import React, { useState, useEffect, useRef } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, ActivityIndicator, Text, View, TextInput, ScrollView, Pressable, Platform, TouchableOpacity } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import BillNotesModal from './BillNotesModal';
import { AntDesign, Fontisto, MaterialCommunityIcons, MaterialIcons, Ionicons, Octicons } from '@expo/vector-icons';
import firebase from 'firebase/compat/app';
import { db } from '../../db/firestore';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { generateBillPDF } from './billPdf'

const Bill = ({ route, navigation, userInfo }) => {
    const { billId, title } = route.params
    const [billInfo, setBillInfo] = useState();
    const [billName, setBillName] = useState();
    const [trackedLegislation, setTrackedLegislation] = useState()
    const [trackedLegislationTitles, setTrackedLegislationTitles] = useState()
    const [isModalVisible, setModalVisible] = useState(false);
    const [sponsors, setSponsors] = useState();
    const [clients, setClients] = useState();
    const [committees, setCommittees] = useState();
    const [statusHistory, setStatusHistory] = useState();
    const [votes, setVotes] = useState();
    const [footnotes, setFootnotes] = useState()
    const [value, setValue] = useState('');
    const [isEnabled, setIsEnabled] = useState(false);
    const [notesEmail, setNotesEmail] = useState();
    const [billHistory, setBillHistory] = useState()
    const [showBillHistory, setShowBillHistory] = useState(false)
    const [showSponsors, setShowSponsors] = useState(false)
    const [showClients, setShowClients] = useState(false)
    const [showCommittees, setShowCommittees] = useState(false)
    const [showStatusHistory, setShowStatusHistory] = useState(false)
    const [showFootnotes, setShowFootnotes] = useState(false)
    const [showVotes, setShowVotes] = useState(false)
    const [showExport, setShowExport] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')
    const [historyEnabled, setHistoryEnabled] = useState(false)
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedPriority, setSelectedPriority] = useState('');
    const [issue, setIssue] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const [selectedExportClient, setSelectedExportClient] = useState();
    const [isFullReport, setIsFullReport] = useState(true);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        billPriority: true,
        billIssue: true,
        clientNotes: true,
        billHistory: true,
        sponsors: true,
        statusHistory: true,
        committees: true,
        votes: true,
        footnotes: true,
        billNotes: true,
        intent: true,
        rDisagree: true,
        dDisagree: true,
        rQuestions: true,
        dQuestions: true,
    });
    const scrollViewRef = useRef(null);
    const headerHeight = useHeaderHeight();

    const chamberType = {
        1: "H",
        2: "S",
    };
    const documentType = {
        1: "B",
        2: "R",
    };
    const chamberObj = {
        1: "House",
        2: "Senate",
    }

    const buildLegisTitle = (billInfo) => {
        return `${chamberType[billInfo.chamber]}${documentType[billInfo.documentType]}${billInfo.number}`;
    }

    const toggleSwitch = async () => {
        if (!isEnabled) {
            // Adding the bill
            const trackedArr = [...trackedLegislation];
            const trackedTitlesArr = [...trackedLegislationTitles];
            trackedArr.push({
                title: billInfo.title,
                id: billInfo.id,
                legislationTitle: billName,
            });
            trackedTitlesArr.push(billName);

            const userRef = doc(db, "users", userInfo.email);
            await updateDoc(userRef, {
                trackedLegislation: trackedArr,
                trackedLegislationTitles: trackedTitlesArr,
            });
            setIsEnabled(true);
        } else {
            // Removing the bill
            const billToRemove = {
                title: billInfo.title,
                id: billInfo.id,
                legislationTitle: billName,
            };
            const userRef = doc(db, "users", userInfo.email);
            await updateDoc(userRef, {
                trackedLegislation: firebase.firestore.FieldValue.arrayRemove(billToRemove),
                trackedLegislationTitles: trackedLegislationTitles.filter(item => item !== billName),
            });

            const filtered = trackedLegislation.filter(item => item.id !== billId);
            const filteredTitles = trackedLegislationTitles.filter(item => item !== billName);
            setTrackedLegislation(filtered);
            setTrackedLegislationTitles(filteredTitles);
            setIsEnabled(false);
        }
    };

    const buildSponsorList = (arr) => {
        if (arr?.length > 0) {
            const sponsorArr = [];
            for (let i = 0; i < arr.length; i += 1) {
                sponsorArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                        <Text style={styles.subTextStyle} key={arr[i].id}>{arr[i].name}- {arr[i].district}</Text>
                    </View>
                )
            }
            setSponsors(sponsorArr)
        }
    }

    const buildClientList = (billName, arr) => {
        if (arr?.length > 0) {
            const clientArr = [];
            arr.forEach((client, i) => {
                // Check if the billName exists in the client's bills array
                const billEntry = client.bills.find((bill) => bill.billName === billName);
                if (billEntry) {
                    clientArr.push(
                        <View
                            style={{
                                backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK,
                                borderRadius: 9,
                                padding: 6,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            key={i}
                        >
                            <View>
                                <Text style={styles.subTextStyle}>
                                    {client.name} - Priority: {billEntry.billPriority}
                                </Text>
                                {billEntry.billIssue && (
                                    <Text style={[styles.subTextStyle, { fontStyle: 'italic', marginTop: 4 }]}>
                                        Issue: {billEntry.billIssue}
                                    </Text>
                                )}
                                <Pressable
                                    style={{
                                        backgroundColor: constants.ICON_COLOR,
                                        borderRadius: 5,
                                        paddingHorizontal: 10,
                                        paddingVertical: 4,
                                        width: '35%'
                                    }}
                                    onPress={() => openComments(billEntry.billNotes, client.email)}
                                >
                                    <Text style={{ color: 'white', fontSize: 12 }}>Notes</Text>
                                </Pressable>
                            </View>
                            <Pressable
                                style={{
                                    backgroundColor: constants.ICON_COLOR,
                                    borderRadius: 5,
                                    paddingHorizontal: 10,
                                    paddingVertical: 4,
                                }}
                                onPress={() => removeClientFromBill(client.email, billName)}
                            >
                                <Text style={{ color: 'white', fontSize: 12 }}>Remove</Text>
                            </Pressable>
                        </View>
                    );
                }
            });
            setClients(clientArr.length > 0 ? clientArr : null);
        }
    };

    const buildCommitteesList = (arr) => {
        if (arr?.length > 0) {
            const committeeArr = [];
            for (let i = 0; i < arr.length; i += 1) {
                committeeArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                        <Text style={styles.subTextStyle} key={arr[i].id}>{arr[i].name} ({chamberObj[arr[i].chamber]})</Text>
                    </View>
                )
            }
            setCommittees(committeeArr)
        }
    }

    const buildBillHistoryList = (arr) => {
        if (arr?.length > 0) {
            const historyArr = [];
            for (let i = 0; i < arr.length; i += 1) {
                if (arr[i].type === "Meeting") {
                    historyArr.push(
                        <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                            <Text style={styles.textStyle}>{arr[i].type}</Text>
                            <Text style={styles.subTextStyle}>{arr[i].committees}</Text>
                            <Text style={styles.subTextStyle}>{arr[i].date?.substring(0, arr[i].date.indexOf('T'))}</Text>
                        </View>
                    )
                } else {
                    historyArr.push(
                        <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                            <Text style={styles.textStyle}>{arr[i].type}</Text>
                            <Text style={styles.subTextStyle}>{arr[i].name}</Text>
                            <Text style={styles.subTextStyle}>{arr[i].date.substring(0, arr[i].date.indexOf('T'))}</Text>
                        </View>
                    )
                }
            }
            setBillHistory(historyArr)
        }
    }

    const buildStatusHistoryList = (arr) => {
        if (arr?.length > 0) {
            const historyArr = [];
            for (let i = 0; i < arr.length; i += 1) {
                historyArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                        <Text style={styles.subTextStyle}>{arr[i].date}</Text>
                        <Text style={styles.subTextStyle}>---{arr[i].name}</Text>
                    </View>
                )
            }
            setStatusHistory(historyArr)
        }
    }

    const buildFootnotesList = (arr) => {
        if (arr?.length > 0 && arr[0] !== '') {
            const footnotesArr = [];
            for (let i = 0; i < arr.length; i += 1) {
                footnotesArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9, padding: 6 }} key={i}>
                        <Text style={styles.subTextStyle}>{arr[i]}</Text>
                    </View>
                )
            }
            setFootnotes(footnotesArr)
        }
    }

    const buildVotesList = (arr) => {
        if (arr?.length > 0) {
            const votesArr = [];
            const billUrl = `https://www.legis.ga.gov/legislation/${billId}`
            for (let i = 0; i < arr.length; i += 1) {
                votesArr.push(
                    <View style={{ backgroundColor: i % 2 === 0 ? '#5C5858' : constants.SECONDARY_DARK, borderRadius: 9 }} key={i}>
                        <Text style={[styles.subTextStyle, { marginLeft: 10, marginBottom: 0, marginTop: 4 }]}>{arr[i].date.substring(0, arr[i].date.indexOf('T'))}</Text>
                        <View style={[styles.voteRow]}>
                            <Pressable
                                onPress={() => navigation.navigate('VotePage', { title: arr[i].name, uri: billUrl, voteId: arr[i].id, options: { headerBackTitleVisible: false } })}
                            >
                                <Text style={[styles.subTextStyle, { marginRight: 15, textDecorationLine: 'underline' }]}>{arr[i].name}:</Text>
                            </Pressable>
                            <View style={{ marginRight: 15 }}>
                                <Text style={styles.subTextStyle}>Yea</Text>
                                <Text style={styles.subTextStyle}>{arr[i].yea}</Text>
                            </View>
                            <View style={{ marginRight: 15 }}>
                                <Text style={styles.subTextStyle}>Nay</Text>
                                <Text style={styles.subTextStyle}>{arr[i].nay}</Text>
                            </View>
                            <View style={{ marginRight: 15 }}>
                                <Text style={styles.subTextStyle}>NV</Text>
                                <Text style={styles.subTextStyle}>{arr[i].notVoting}</Text>
                            </View>
                            <View style={{ marginRight: 15 }}>
                                <Text style={styles.subTextStyle}>Excused</Text>
                                <Text style={styles.subTextStyle}>{arr[i].excused}</Text>
                            </View>
                        </View>
                    </View>
                )
            }
            setVotes(votesArr)
        }
    }

    const handleSaveNotes = async () => {
        if (value !== '') {
            const userRef = doc(db, "users", userInfo.email);
            if (!notesEmail) {
                // Save to general legislationNotes
                let updatedLegislationNotes = userInfo.legislationNotes;
                const objIndex = updatedLegislationNotes?.findIndex(e => e.id === billId);
                if (objIndex !== -1 && objIndex !== undefined) {
                    updatedLegislationNotes[objIndex].notes = value;
                    await updateDoc(userRef, {
                        legislationNotes: updatedLegislationNotes,
                    });
                } else {
                    if (!updatedLegislationNotes) {
                        updatedLegislationNotes = [{
                            id: billId,
                            notes: value,
                        }];
                    } else {
                        updatedLegislationNotes.push({
                            id: billId,
                            notes: value,
                        });
                    }
                    await updateDoc(userRef, {
                        legislationNotes: updatedLegislationNotes,
                    });
                }
                setNotesEmail()
            } else {
                // Save to the specific client in clientList
                const updatedClientList = [...userInfo.clientList];
                const clientIndex = updatedClientList.findIndex(client => client.email === notesEmail);

                if (clientIndex !== -1) {
                    const client = updatedClientList[clientIndex];
                    const billIndex = client.bills.findIndex(bill => bill.billId === billId);

                    if (billIndex !== -1) {
                        // Update existing billNotes
                        client.bills[billIndex].billNotes = value;
                    } else {
                        console.error("Bill not found for the specified client.");
                        return;
                    }

                    // Save the updated clientList to Firestore
                    await updateDoc(userRef, {
                        clientList: updatedClientList,
                    });
                } else {
                    console.error("Client not found.");
                }
                setNotesEmail()
            }
        }
    };

    useEffect(() => {
        async function getBillData() {
            setPageLoading(true)
            // call to database for specific bill
            const trackedArr = [];
            for (let i = 0; i < userInfo.trackedLegislation.length; i += 1) {
                trackedArr.push(userInfo.trackedLegislation[i])
            }
            setTrackedLegislation(trackedArr)
            setTrackedLegislationTitles(userInfo.trackedLegislationTitles)
            const docRef = doc(db, "legislation", `${billId}`);
            await getDoc(docRef)
                .then(doc => doc.data())
                .then(data => {
                    const sortedHistory = data.billHistory?.sort(function (a, b) {
                        return new Date(b.date) - new Date(a.date);
                    });
                    if (sortedHistory) {
                        buildBillHistoryList(sortedHistory)
                    }
                    setBillInfo(data)
                    setBillName(buildLegisTitle(data))
                    setPdfUrl(`https://www.legis.ga.gov/api/legislation/document/${data.session.library}/${data.versions[0].id}`)
                    buildSponsorList(data.sponsors)
                    buildClientList(buildLegisTitle(data), userInfo.clientList)
                    buildCommitteesList(data.committees)
                    buildStatusHistoryList(data.statusHistory)
                    buildFootnotesList(data.footnotes.split(';'))
                    buildVotesList(data.votes)

                    if (trackedArr.some(e => e.id === data.id)) {
                        setIsEnabled(true)
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            setPageLoading(false)
        }
        getBillData()
    }, [userInfo])

    const goToAnalysis = () => {
        navigation.navigate('AIAnalysis', { title: 'Bill Analysis', billData: billInfo, options: { headerBackTitleVisible: false } })
    }

    const goToCurrentVersion = () => {
        navigation.navigate('ExternalView', { title: title, link: pdfUrl, isBill: true, options: { headerBackTitleVisible: false } })
    }

    const openComments = (notes, clientEmail) => {
        setNotesEmail(clientEmail)
        setValue(notes ? notes : '')
        setModalVisible(true);
    };

    const closeComments = () => {
        setModalVisible(false);
    };

    const openHistory = () => {
        if (billInfo.billHistory) {
            setShowBillHistory(!showBillHistory)
            setHistoryEnabled(!historyEnabled)
        }
    }

    const assignBillToClient = async () => {
        if (!selectedClient || !selectedPriority) {
            return;
        }

        const billSubject = billInfo.title;
        const clientSponsorList = [];
        if (billInfo.sponsors?.length > 0) {
            for (let i = 0; i < billInfo.sponsors.length; i += 1) {
                const sponsor = billInfo.sponsors[i]
                clientSponsorList.push(`${sponsor.name} (${chamberType[sponsor.chamber]}) ${sponsor.district}`)
            }
        }

        // Update client list
        const updatedClientList = userInfo.clientList.map((client) => {
            if (client.email === selectedClient) {
                const billExists = client.bills.some((bill) => bill.billName === billName);
                if (!billExists) {
                    client.bills.push({
                        billId: billInfo.id,
                        billName,
                        billSubject,
                        billSummary: billInfo.firstReader,
                        billSponsors: clientSponsorList,
                        billPriority: selectedPriority,
                        billIssue: issue || '', // Add issue field
                    });
                }
            }
            return client;
        });

        try {
            const docRef = doc(db, 'users', userInfo.email);
            await updateDoc(docRef, { clientList: updatedClientList });
        } catch (error) {
            console.error('Error assigning bill: ', error);
        }
        setSelectedClient('');
        setSelectedPriority('');
        setIssue(''); // Reset issue input
    };


    const removeClientFromBill = async (clientEmail, billName) => {
        try {
            // Update the client's bills in the userInfo.clientList
            const updatedClientList = userInfo.clientList.map((client) => {
                if (client.email === clientEmail) {
                    client.bills = client.bills.filter((bill) => bill.billName !== billName);
                }
                return client;
            });

            // Update Firestore
            const docRef = doc(db, 'users', userInfo.email);
            await updateDoc(docRef, { clientList: updatedClientList });
            buildClientList(billName, updatedClientList);
        } catch (error) {
            console.error('Error removing client from bill:', error);
        }
    };

    const CustomCheckbox = ({ label, value, onValueChange, disabled }) => (
        <TouchableOpacity
            style={[
                styles.checkboxContainer,
                disabled && styles.checkboxContainerDisabled, // Add a style for disabled state
            ]}
            onPress={() => !disabled && onValueChange(!value)} // Prevent action when disabled
            activeOpacity={disabled ? 1 : 0.7} // Disable opacity change when disabled
        >
            <View
                style={[
                    styles.checkbox,
                    value && styles.checkboxChecked,
                    disabled && styles.checkboxDisabled, // Add a style for disabled checkbox
                ]}
            >
                {/* Display check mark when checked */}
                {value && (
                    <Ionicons
                        name="checkmark" // Checkmark icon
                        size={20}
                        color="white" // Color of the checkmark
                        style={styles.checkmarkIcon} // Optional styling for the icon
                    />
                )}
            </View>
            <Text
                style={[
                    styles.subTextStyle,
                    disabled && styles.subTextDisabled, // Add a style for disabled text
                ]}
            >
                {label}
            </Text>
        </TouchableOpacity>
    );


    const resetSelectedOptionsForFullReport = () => {
        setSelectedOptions({
            billPriority: true,
            billIssue: true,
            clientNotes: true,
            billHistory: true,
            sponsors: true,
            statusHistory: true,
            committees: true,
            votes: true,
            footnotes: true,
            billNotes: true,
            ...(userInfo.tier === "3" && {
                intent: true,
                rDisagree: true,
                dDisagree: true,
                rQuestions: true,
                dQuestions: true,
            }),
        });
    };

    const handleFullReportToggle = () => {
        setIsFullReport(true);
        resetSelectedOptionsForFullReport();
    };

    const handleCustomizeToggle = () => {
        setIsFullReport(false);
    };

    const handleCheck = (option) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [option]: !prev[option],
        }));
    };

    const handleExportPDF = async (type) => {
        setPdfLoading(true)
        let billPriority;
        let billIssue;
        let clientNotes;
        const selectedFields = Object.keys(selectedOptions).filter(
            (key) => selectedOptions[key]
        );

        const billNote = userInfo.legislationNotes.find(
            (note) => note.id === billInfo.id
        );

        if (selectedExportClient && selectedExportClient !== '') {
            // Find the selected client by email
            const selectedClient = userInfo.clientList.find(
                (client) => client.email === selectedExportClient
            );

            // Find the bill in the selected client's bills list
            const selectedBill = selectedClient.bills.find(
                (bill) => bill.billName === billName
            );

            if (!selectedBill) {
                console.error("Bill not found for the selected client.");
                return;
            }
            billPriority = selectedBill.billPriority;
            billIssue = selectedBill.billIssue;
            clientNotes = selectedBill.billNotes;
        }
        if (type === 'pdf') {
            await generateBillPDF(billInfo, billName, selectedFields, billPriority, billIssue, clientNotes, billNote?.notes, pdfUrl);
        } else {
            await generateBillPDF(billInfo, billName, selectedFields, billPriority, billIssue, clientNotes, billNote?.notes, pdfUrl, selectedExportClient)
        }
        setPdfLoading(false)
    };

    useEffect(() => {
        if (showExport || showVotes || showFootnotes) {
            const scrollableElement = scrollViewRef.current;
            scrollableElement.scrollToEnd()
        }
    }, [showExport, showVotes, showFootnotes]);


    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                {pageLoading ?
                    <ActivityIndicator size="large" color={constants.ICON_COLOR} />
                    :
                    <View style={{ flex: 1 }}>
                        <View style={styles.rowContainer}>
                            <Text style={[styles.textStyle, { marginLeft: 10 }]}>{billInfo?.title}</Text>
                            <Pressable onPress={toggleSwitch} style={{ marginRight: 8 }}>
                                {isEnabled ?
                                    <Ionicons name="notifications" size={30} color={constants.ICON_COLOR} />
                                    :
                                    <Ionicons name="md-notifications-off-outline" size={30} color={constants.ICON_COLOR} />
                                }
                            </Pressable>
                        </View>
                        <View style={{ flex: 1 }}>
                            <ScrollView style={styles.scrollView} id='billScrollView' ref={scrollViewRef}>
                                <View style={[styles.card, styles.buttonClose]}>
                                    <Text style={styles.textStyle}>First Reader Summary:</Text>
                                    <Text style={styles.subTextStyle}>{billInfo?.firstReader}</Text>
                                    <Pressable
                                        onPress={() => {
                                            let notes
                                            const legisNotesIndex = userInfo?.legislationNotes?.findIndex(e => e.id === billId)
                                            if (legisNotesIndex !== -1 && legisNotesIndex !== undefined) {
                                                notes = userInfo.legislationNotes[legisNotesIndex].notes
                                            }
                                            openComments(notes)
                                        }}
                                    >
                                        <Text style={[styles.textStyle, { alignSelf: 'center', color: constants.ICON_COLOR, marginTop: 6 }]}>My Legislation Notes</Text>
                                    </Pressable>
                                    <BillNotesModal
                                        visible={isModalVisible}
                                        onClose={closeComments}
                                        onSave={() => handleSaveNotes()}
                                        value={value}
                                        setValue={setValue}
                                    />
                                </View>
                                {billInfo?.analysis ?
                                    <View style={{ flexDirection: 'row' }}>
                                        <Pressable
                                            disabled={userInfo.tier === '2' ? true : false}
                                            style={[styles.card, styles.buttonClose, { flexDirection: 'row', opacity: userInfo.tier === '3' ? 1 : .5 }]}
                                            onPress={goToAnalysis}
                                        >
                                            {userInfo.tier === '2' ?
                                                <Fontisto name="locked" size={20} color={constants.ICON_COLOR} style={{ marginRight: 4 }} />
                                                :
                                                <MaterialCommunityIcons name="book-search-outline" size={24} color={constants.ICON_COLOR} />

                                            }
                                            <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Bill Analysis</Text>
                                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right', display: userInfo.tier === '2' ? 'none' : 'block' }} />
                                        </Pressable>
                                    </View>
                                    :
                                    null
                                }
                                <View style={{ flexDirection: 'row' }}>
                                    <Pressable
                                        style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                        onPress={goToCurrentVersion}
                                    >
                                        <AntDesign name="filetext1" size={24} color={constants.ICON_COLOR} />
                                        <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Current Version</Text>
                                        <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                    </Pressable>
                                </View>
                                {billHistory ?
                                    <View >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowBillHistory(!showBillHistory)}
                                            >
                                                <MaterialCommunityIcons name="history" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Bill History</Text>
                                                {showBillHistory ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showBillHistory ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {billHistory}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {sponsors ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowSponsors(!showSponsors)}
                                            >
                                                <AntDesign name="team" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Sponsors</Text>
                                                {showSponsors ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showSponsors ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {sponsors}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                <View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Pressable
                                            style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                            onPress={() => setShowClients(!showClients)}
                                        >
                                            <Octicons name="person-add" size={24} color={constants.ICON_COLOR} />
                                            <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Clients</Text>
                                            {showClients ? (
                                                <AntDesign
                                                    name="minus"
                                                    size={24}
                                                    color={constants.ICON_COLOR}
                                                    style={{ flex: 1, textAlign: 'right' }}
                                                />
                                            ) : (
                                                <AntDesign
                                                    name="plus"
                                                    size={24}
                                                    color={constants.ICON_COLOR}
                                                    style={{ flex: 1, textAlign: 'right' }}
                                                />
                                            )}
                                        </Pressable>
                                    </View>
                                    {showClients ? (
                                        <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                                                <Dropdown
                                                    style={[styles.dropdown, { flex: 1, marginRight: 10 }]}
                                                    data={userInfo.clientList.map((client) => ({
                                                        label: `${client.name} (${client.email})`,
                                                        value: client.email,
                                                    }))}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder="Select Client"
                                                    placeholderStyle={{ color: constants.SUBTEXT_COLOR }}
                                                    selectedTextStyle={{ color: constants.SUBTEXT_COLOR }}
                                                    itemTextStyle={{ color: constants.SUBTEXT_COLOR }}
                                                    value={selectedClient}
                                                    containerStyle={{ backgroundColor: constants.SECONDARY_DARK }}
                                                    activeColor={constants.PRIMARY_DARK}
                                                    onChange={(item) => setSelectedClient(item.value)}
                                                />
                                                <Dropdown
                                                    style={[styles.dropdown, { flex: 1, marginHorizontal: 10 }]}
                                                    data={['High', 'Moderate', 'Low'].map((priority) => ({
                                                        label: priority,
                                                        value: priority,
                                                    }))}
                                                    labelField="label"
                                                    valueField="value"
                                                    placeholder="Select Priority"
                                                    placeholderStyle={{ color: constants.SUBTEXT_COLOR }}
                                                    selectedTextStyle={{ color: constants.SUBTEXT_COLOR }}
                                                    itemTextStyle={{ color: constants.SUBTEXT_COLOR }}
                                                    value={selectedPriority}
                                                    containerStyle={{ backgroundColor: constants.SECONDARY_DARK }}
                                                    activeColor={constants.PRIMARY_DARK}
                                                    onChange={(item) => setSelectedPriority(item.value)}
                                                />
                                                <TextInput
                                                    style={{
                                                        flex: 1,
                                                        backgroundColor: constants.SECONDARY_LIGHT,
                                                        borderRadius: 5,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        borderWidth: 1,
                                                        borderColor: 'black',
                                                        color: constants.SUBTEXT_COLOR,
                                                        fontSize: 15,
                                                    }}
                                                    placeholder="Enter Issue (Optional)"
                                                    placeholderTextColor={constants.SUBTEXT_COLOR}
                                                    value={issue}
                                                    onChangeText={setIssue}
                                                />
                                            </View>
                                            <Pressable
                                                style={{
                                                    alignSelf: 'center',
                                                    backgroundColor: constants.ICON_COLOR,
                                                    borderRadius: 5,
                                                    paddingHorizontal: 20,
                                                    paddingVertical: 8,
                                                    marginBottom: 10,
                                                }}
                                                onPress={assignBillToClient}
                                                disabled={!selectedClient || !selectedPriority}
                                            >
                                                <Text style={[styles.textStyle, { color: 'white' }]}>Assign</Text>
                                            </Pressable>
                                            {clients}
                                        </View>
                                    ) : null}
                                </View>
                                {committees ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowCommittees(!showCommittees)}
                                            >
                                                <Ionicons name="clipboard-outline" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Committees</Text>
                                                {showCommittees ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showCommittees ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {committees}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {statusHistory ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowStatusHistory(!showStatusHistory)}
                                            >
                                                <AntDesign name="check" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Status History</Text>
                                                {showStatusHistory ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showStatusHistory ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {statusHistory}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {footnotes ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowFootnotes(!showFootnotes)}
                                            >
                                                <MaterialIcons name="notes" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Footnotes</Text>
                                                {showFootnotes ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showFootnotes ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {footnotes}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                {votes ?
                                    <View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Pressable
                                                style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                                onPress={() => setShowVotes(!showVotes)}
                                            >
                                                <MaterialCommunityIcons name="vote" size={24} color={constants.ICON_COLOR} />
                                                <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Votes</Text>
                                                {showVotes ?
                                                    <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                    :
                                                    <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                }
                                            </Pressable>
                                        </View>
                                        {showVotes ?
                                            <View style={[styles.card, styles.buttonClose, { marginTop: -5 }]}>
                                                {votes}
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                    :
                                    null
                                }
                                <View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Pressable
                                            style={[styles.card, styles.buttonClose, { flexDirection: 'row' }]}
                                            onPress={() => setShowExport((prev) => !prev)}
                                        >
                                            <AntDesign name="export" size={24} color={constants.ICON_COLOR} />
                                            <Text style={[styles.textStyle, { marginLeft: 8, alignSelf: 'center' }]}>Export/Email Bill</Text>
                                            {showExport ?
                                                <AntDesign name="minus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                                :
                                                <AntDesign name="plus" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                                            }
                                        </Pressable>
                                    </View>
                                    {showExport && (
                                        <View style={[styles.card, styles.buttonClose]} id='exportElement'>
                                            {/* Client Dropdown */}
                                            <Dropdown
                                                style={[styles.dropdown, { flex: 1, marginBottom: 10 }]}
                                                data={[
                                                    { label: 'No Client', value: '' },  // This option allows unselecting a client
                                                    ...userInfo.clientList
                                                        .filter((client) => client.bills.some((bill) => bill.billName === billName))
                                                        .map((client) => ({
                                                            label: `${client.name} (${client.email})`,
                                                            value: client.email,
                                                        }))
                                                ]}
                                                labelField="label"
                                                valueField="value"
                                                placeholder="Select Client"
                                                placeholderStyle={{ color: constants.SUBTEXT_COLOR }}
                                                selectedTextStyle={{ color: constants.SUBTEXT_COLOR }}
                                                itemTextStyle={{ color: constants.SUBTEXT_COLOR }}
                                                value={selectedExportClient}
                                                containerStyle={{ backgroundColor: constants.SECONDARY_DARK }}
                                                activeColor={constants.PRIMARY_DARK}
                                                onChange={(item) => setSelectedExportClient(item.value)} // Set selected client or empty string
                                            />

                                            {/* Main Options (First Column) */}
                                            <View style={styles.exportRowContainer}>
                                                <View style={styles.column}>
                                                    <CustomCheckbox
                                                        label="Include Full Report"
                                                        value={isFullReport}
                                                        onValueChange={handleFullReportToggle}
                                                    />
                                                    <CustomCheckbox
                                                        label="Customize Report"
                                                        value={!isFullReport}
                                                        onValueChange={handleCustomizeToggle}
                                                    />
                                                </View>

                                                {/* Conditional Customization Options */}
                                                {!isFullReport && (
                                                    <>
                                                        {/* Second Column */}
                                                        <View style={styles.column}>
                                                            <Text style={styles.sectionHeader}>My Info</Text>
                                                            {Object.entries({
                                                                'Client Priority': 'billPriority',
                                                                'Client Issue': 'billIssue',
                                                                'Client Notes': 'clientNotes',
                                                                'My Legislation Notes': 'billNotes',
                                                            }).map(([label, field]) => (
                                                                <CustomCheckbox
                                                                    key={field}
                                                                    label={label}
                                                                    value={selectedOptions[field]}
                                                                    onValueChange={() => handleCheck(field)}
                                                                    disabled={['billPriority', 'billIssue', 'clientNotes'].includes(field) && !selectedExportClient}
                                                                />
                                                            ))}
                                                        </View>

                                                        {/* Third Column */}
                                                        <View style={styles.column}>
                                                            <Text style={styles.sectionHeader}>Bill Info</Text>
                                                            {Object.entries({
                                                                'Bill History': 'billHistory',
                                                                'Sponsors': 'sponsors',
                                                                'Status History': 'statusHistory',
                                                                'Committees': 'committees',
                                                                'Votes': 'votes',
                                                                'Footnotes': 'footnotes',
                                                            }).map(([label, field]) => (
                                                                <CustomCheckbox
                                                                    key={field}
                                                                    label={label}
                                                                    value={selectedOptions[field]}
                                                                    onValueChange={() => handleCheck(field)}
                                                                />
                                                            ))}
                                                        </View>

                                                        {/* Last Column (Bill Analysis) */}
                                                        {userInfo.tier === "3" && (
                                                            <View style={styles.column}>
                                                                <Text style={styles.sectionHeader}>Bill Analysis</Text>
                                                                {Object.entries({
                                                                    'Intent': 'intent',
                                                                    'Republican Disagreement': 'rDisagree',
                                                                    'Democrat Disagreement': 'dDisagree',
                                                                    'Republican Questions': 'rQuestions',
                                                                    'Democrat Questions': 'dQuestions',
                                                                }).map(([label, field]) => (
                                                                    <CustomCheckbox
                                                                        key={field}
                                                                        label={label}
                                                                        value={selectedOptions[field]}
                                                                        onValueChange={() => handleCheck(field)}
                                                                    />
                                                                ))}
                                                            </View>
                                                        )}
                                                    </>
                                                )}
                                            </View>

                                            {/* Buttons Container */}
                                            <View style={styles.buttonContainer}>
                                                {/* Export Button */}
                                                <Pressable
                                                    style={[styles.exportButton, styles.firstButton]}
                                                    onPress={() => handleExportPDF('pdf')}
                                                >
                                                    <Text style={styles.buttonText}>Export Bill PDF</Text>
                                                </Pressable>

                                                {/* Email Button */}
                                                {selectedExportClient && (
                                                    <Pressable
                                                        style={styles.exportButton}
                                                        onPress={() => handleExportPDF('email')}
                                                    >
                                                        <View style={styles.inlineContainer}>
                                                            <Text style={styles.buttonText}>Email Report to Client</Text>
                                                            {pdfLoading && (
                                                                <ActivityIndicator size="small" color="white" style={styles.indicator} />
                                                            )}
                                                        </View>
                                                    </Pressable>
                                                )}
                                            </View>
                                        </View>
                                    )}
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                }
            </View>
        </View>
    )
}

export default Bill

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        flex: 1
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', // Optional: to vertically center the elements
        padding: 10, // Optional: add some padding if needed
        width: "100%",
    },
    voteRow: {
        flexDirection: 'row',
        flexGrow: 1,
        width: "95%",
        padding: 10
    },
    trackedButton: {
        width: 110,
        padding: 7,
        borderRadius: 20,
    },
    card: {
        borderRadius: 9,
        borderWidth: 3,
        borderColor: 'black',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 12,
        elevation: 2,
        width: "100%",
        flexGrow: 1,
        marginBottom: 1,
    },
    button: {
        flexDirection: 'row',
        width: "95%",
        height: 85,
        borderBottomColor: 'gray',
        borderBottomWidth: .2,
        textAlignVertical: 'center',
        alignItems: 'center',
        paddingTop: 20
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    notesStyle: {
        padding: 10,
        color: constants.TITLE_COLOR,
        fontFamily: 'Verdana'
    },
    notesContainer: {
        backgroundColor: '#5C5858',
        borderColor: constants.SUBTEXT_COLOR,
        opacity: .7,
        borderWidth: 1,
        borderRadius: 9,
        margin: 10,
        height: 230,
    },
    scrollView: {
        marginLeft: 10
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    dropdown: {
        backgroundColor: constants.SECONDARY_DARK,
        borderRadius: 5,
        height: 40,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: 'black',
    },
    optionsContainer: {
        marginBottom: 10,
    },
    exportRowContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        alignItems: 'flex-start', // Optional: to vertically center the elements
        padding: 10, // Optional: add some padding if needed
        width: "100%",
    },
    column: {
        flex: 1,
        alignItems: 'flex-start',
    },
    sectionHeader: {
        fontWeight: 'bold',
        marginBottom: 10,
        color: constants.SUBTEXT_COLOR,
        textDecorationLine: 'underline'
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: 'black',
        marginRight: 8,
        backgroundColor: constants.SUBTEXT_COLOR,
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkmarkIcon: {
        position: 'absolute', // Position the checkmark inside the checkbox
    },
    checkboxChecked: {
        backgroundColor: constants.ICON_COLOR,
    },
    checkboxContainerDisabled: {
        display: 'none',
        opacity: 0.5, // Dim the checkbox container
    },
    checkboxDisabled: {
        backgroundColor: '#ccc', // Change the background color for disabled checkbox
    },
    subTextDisabled: {
        color: '#aaa', // Dim the text color
    },
    label: {
        marginLeft: 8,
        fontSize: 14,
        color: 'white',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10, // Optional, for spacing above the buttons
    },
    inlineContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    indicator: {
        marginLeft: 10, // Adjust spacing between text and ActivityIndicator
    },
    firstButton: {
        marginRight: 10, // Small gap between the first and second buttons
    },
    exportButton: {
        alignSelf: 'center',
        backgroundColor: constants.ICON_COLOR,
        borderRadius: 5,
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 20,
        height: 35, // Fixed height for consistency
        justifyContent: 'center', // Center text and spinner vertically
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    textStyle: {
        color: constants.TITLE_COLOR,
        marginBottom: 2,
        marginLeft: 2,
        fontSize: 16,
        fontWeight: "bold",
        textAlignVertical: "center",
    },
    subTextStyle: {
        color: constants.SUBTEXT_COLOR,
        marginBottom: 6,
        fontSize: 13,
        fontWeight: "bold",
    },
})

