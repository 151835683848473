import React, { useEffect, useState } from 'react'
import { useHeaderHeight } from '@react-navigation/elements';
import constants from '../../utils/constants';
import { StyleSheet, Text, View, Pressable, Platform, ScrollView } from 'react-native'
import { AntDesign, Ionicons, MaterialIcons, Fontisto, Octicons } from '@expo/vector-icons';
import { signOut } from "firebase/auth";
import { auth, db } from '../../db/firestore';
import { doc, updateDoc, getDoc } from "firebase/firestore";

const Account = ({ navigation, userInfo }) => {
    const [tier, setTier] = useState('1')
    const headerHeight = useHeaderHeight();

    useEffect(() => {
        if (userInfo) {
            setTier(userInfo.tier)
        }
    }, [userInfo])

    const handleLogout = async () => {
        const userRef = doc(db, "users", userInfo.email);
        await updateDoc(userRef, {
            loggedInDevices: userInfo.loggedInDevices ? userInfo.loggedInDevices - 1 : 0
        })
        signOut(auth).then(() => {
            navigation.reset({
                index: 0,
                routes: [{ name: 'LogIn' }],
            });
            // Sign-out successful.
        }).catch((error) => {
            console.log("error")
            // An error happened.
        });
    }
    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK, marginTop: headerHeight, flex: 1 }}>
            <ScrollView>
                <View style={styles.centeredView}>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => navigation.navigate('Profile', {})}
                        >
                            <AntDesign name="user" size={24} color={constants.ICON_COLOR} />
                            <Text style={styles.textStyle}>Profile</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('CommitteeTracking', {})}
                        >
                            {tier === '1' ?
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <AntDesign name="team" size={24} color={constants.ICON_COLOR} />

                            }
                            <Text style={styles.textStyle}>Committee Tracking</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('LegislationTracking', {})}
                        >
                            {tier === '1' ?
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <AntDesign name="filetext1" size={24} color={constants.ICON_COLOR} />

                            }
                            <Text style={styles.textStyle}>Legislation Tracking</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('KeywordTracking', {})}
                        >
                            {tier === '1' ?
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <Ionicons name="text" size={24} color={constants.ICON_COLOR} />

                            }
                            <Text style={styles.textStyle}>Keyword Tracking</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            disabled={tier === '2' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '2' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('StatAgTracking', {})}
                        >
                            {tier === '2' ?
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <Ionicons name="clipboard-outline" size={24} color={constants.ICON_COLOR} />

                            }
                            <Text style={styles.textStyle}>State Agency Tracking</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            disabled={tier === '1' ? true : false}
                            style={[styles.button, styles.buttonClose, { opacity: tier === '1' ? .5 : 1 }]}
                            onPress={() => navigation.navigate('ClientList', {})}
                        >
                            {tier === '1' ?
                                <Fontisto name="locked" size={24} color={constants.ICON_COLOR} style={styles.buttonIcon} />
                                :
                                <Octicons name="person-add" size={26} color={constants.ICON_COLOR} />

                            }
                            <Text style={styles.textStyle}>Client List</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => navigation.navigate('GoogleCalendars', {})}
                        >
                            <AntDesign name="google" size={24} color={constants.ICON_COLOR} />
                            <Text style={styles.textStyle}>Google Calendars</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => navigation.navigate('Profile', { page: 'legal' })}
                        >
                            <AntDesign name="filetext1" size={24} color={constants.ICON_COLOR} />
                            <Text style={styles.textStyle}>Legal Disclaimer</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={handleLogout}
                        >
                            <MaterialIcons name="logout" size={24} color={constants.ICON_COLOR} />
                            <Text style={styles.textStyle}>Logout</Text>
                            <Ionicons name="chevron-forward" size={24} color={constants.ICON_COLOR} style={{ flex: 1, textAlign: 'right' }} />
                        </Pressable>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

export default Account

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        backgroundColor: constants.PRIMARY_DARK,
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    button: {
        flexDirection: 'row',
        width: "100%",
        borderBottomColor: 'gray',
        borderBottomWidth: .2,
        textAlignVertical: 'center',
        alignItems: 'center',
        ...Platform.select({
            ios: {
                height: 85,
                paddingTop: 20,
            },
            android: {
                height: 80,
                paddingTop: 15,
            },
            web: {
                height: 80,
                paddingTop: 15,
            }
        })
    },
    buttonOpen: {
        backgroundColor: constants.PRIMARY_DARK,
        textAlignVertical: 'center',
    },
    buttonClose: {
        backgroundColor: constants.PRIMARY_DARK,
        textAlignVertical: 'center',
    },
    textStyle: {
        marginLeft: 10,
        fontSize: 19,
        fontWeight: '400',
        fontFamily: 'Verdana',
        textAlignVertical: "center",
        color: constants.SUBTEXT_COLOR,
    },
})
