import { useEffect, useState } from "react";
import { useHeaderHeight } from '@react-navigation/elements';
import constants from "../../utils/constants";
import { StyleSheet, Text, View, Image, TouchableOpacity, Linking } from "react-native";
import { db } from "../../db/firestore";
import { doc, updateDoc } from "firebase/firestore";

const GoogleCalendars = ({ route, userInfo }) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const headerHeight = useHeaderHeight();

    useEffect(() => {
        if (userInfo.googleCalendarsEnabled === true) {
            setIsEnabled(true);
        } else {
            setIsEnabled(false);
        }
    }, [userInfo]);

    const handleSignIn = () => {
        const userEmail = encodeURIComponent(userInfo.email);
        Linking.openURL(`https://api.clarityinfo.org/googleAuth?email=${userEmail}`);
        setIsEnabled(true);
    };

    const handleSignOut = async () => {
        const userRef = doc(db, "users", userInfo.email);
        await updateDoc(userRef, {
            googleCalendarsEnabled: false,
        });
        setIsEnabled(false);
    };

    const toggleGoogleCalendar = async () => {
        if (!isEnabled) {
            if (userInfo?.googleRefreshToken) {
                const userRef = doc(db, "users", userInfo.email);
                await updateDoc(userRef, {
                    googleCalendarsEnabled: true,
                });
                setIsEnabled(true);
            } else {
                handleSignIn();
            }
        } else {
            handleSignOut();
        }
    };

    return (
        <View style={{ height: '100%', backgroundColor: constants.PRIMARY_DARK }}>
            <View style={[styles.container, { marginTop: headerHeight }]}>
                <View style={[styles.button, styles.buttonClose]}>
                    <View style={styles.rowContainer}>
                        <View style={{ flex: 5, justifyContent: 'center' }}>
                            <Text style={styles.headingText}>Send Events To Your Google Calendar</Text>
                        </View>
                        <View style={{ flex: 1, justifyContent: 'center' }}>
                            {isEnabled ? (
                                <TouchableOpacity onPress={toggleGoogleCalendar} style={styles.disableButton}>
                                    <Text style={styles.disableButtonText}>Disable</Text>
                                </TouchableOpacity>
                            ) : (
                                <TouchableOpacity onPress={toggleGoogleCalendar}>
                                    <Image
                                        source={require('../../assets/web_neutral_sq_SI@2x.png')}
                                        style={styles.signInButton}
                                    />
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default GoogleCalendars;

const styles = StyleSheet.create({
    container: {
        backgroundColor: constants.PRIMARY_DARK,
        marginLeft: 7,
    },
    rowContainer: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
        width: '96%',
    },
    button: {
        borderRadius: 5,
        borderWidth: 2,
        borderColor: '#131313',
        paddingTop: 5,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 6,
        elevation: 2,
        alignSelf: 'center',
        width: "98%",
        marginBottom: 1,
    },
    buttonOpen: {
        backgroundColor: "white",
    },
    buttonClose: {
        backgroundColor: constants.SECONDARY_DARK,
    },
    headingText: {
        color: constants.TITLE_COLOR,
        fontSize: 17,
        fontFamily: 'Verdana',
        fontWeight: "bold",
    },
    signInButton: {
        width: 200, // Adjust the size as needed
        height: 80,
        resizeMode: 'contain',
    },
    disableButton: {
        backgroundColor: constants.TITLE_COLOR,
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#131313',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1, // Ensures the button takes up the full width available
    },
    disableButtonText: {
        color: constants.SECONDARY_DARK,
        fontSize: 16,
        fontFamily: 'Verdana',
        fontWeight: "bold",
        textAlign: 'center', // Ensures the text is centered
    },
});
