import React, { useEffect, useRef, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import workerSrc from 'pdfjs-dist/build/pdf.worker.min.mjs';
import constants from '../../utils/constants';

GlobalWorkerOptions.workerSrc = workerSrc;

// Test this a lot. It seems to work well but ther was one time where it 
// didn't with this code.---pretty sure. If I need to go back to the old
// code, it is on github.

const PDFViewer = ({ pdfUrl, onLinkClick }) => {
    const containerRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const cleanupFns = useRef([]); // Store event listeners for cleanup

    useEffect(() => {
        let isMounted = true;

        const renderPDF = async () => {
            if (!containerRef.current) return;

            setLoading(true);
            containerRef.current.innerHTML = ''; // Clear previous PDF

            try {
                const loadingTask = getDocument(pdfUrl);
                const pdf = await loadingTask.promise;

                if (!isMounted) return;

                const newCleanupFns = [];

                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const viewport = page.getViewport({ scale: 1 });
                    const scale = containerRef.current.clientWidth / viewport.width;
                    const scaledViewport = page.getViewport({ scale });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');

                    canvas.width = scaledViewport.width;
                    canvas.height = scaledViewport.height;
                    canvas.style.marginBottom = '10px'; // Add space between pages

                    containerRef.current.appendChild(canvas);

                    const renderContext = { canvasContext: context, viewport: scaledViewport };
                    await page.render(renderContext).promise;

                    const annotations = await page.getAnnotations();

                    const handleClick = (event) => {
                        const rect = canvas.getBoundingClientRect();
                        const x = (event.clientX - rect.left) * (viewport.width / canvas.width);
                        const y = (event.clientY - rect.top) * (viewport.height / canvas.height);
                        const adjustedY = viewport.height - y;

                        annotations.forEach((annotation) => {
                            if (annotation.subtype === 'Link' && annotation.url) {
                                const [minX, minY, maxX, maxY] = [
                                    Math.min(annotation.rect[0], annotation.rect[2]),
                                    Math.min(annotation.rect[1], annotation.rect[3]),
                                    Math.max(annotation.rect[0], annotation.rect[2]),
                                    Math.max(annotation.rect[1], annotation.rect[3]),
                                ];

                                if (x >= minX && x <= maxX && adjustedY >= minY && adjustedY <= maxY) {
                                    onLinkClick(annotation.url);
                                }
                            }
                        });
                    };

                    canvas.addEventListener('click', handleClick);
                    newCleanupFns.push(() => canvas.removeEventListener('click', handleClick));
                }

                // Store cleanup functions to remove event listeners later
                cleanupFns.current = newCleanupFns;
            } catch (error) {
                console.error('Error rendering PDF:', error);
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        renderPDF();

        return () => {
            isMounted = false;
            if (containerRef.current) containerRef.current.innerHTML = ''; // Clear container

            // Run cleanup functions for event listeners
            cleanupFns.current.forEach((cleanupFn) => cleanupFn());
            cleanupFns.current = [];
        };
    }, [pdfUrl, onLinkClick]);

    return (
        <View style={{ width: '80%', height: '100%', overflow: 'auto', position: 'relative' }}>
            {loading && <ActivityIndicator size="large" color={constants.ICON_COLOR} style={{ marginTop: 200 }} />}
            <div ref={containerRef} style={{ width: '100%', height: '100%', overflow: 'auto' }}></div>
        </View>
    );
};

export default PDFViewer;